@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto&display=swap";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
/* ====================font-family==================== */
@font-face {
  font-family: mundial-light;
  src: url("../fonts/MundialLight.otf");
}
@font-face {
  font-family: mundial-bold;
  src: url("../fonts/MundialBlack.otf");
}
@font-face {
  font-family: mundial-semibold;
  src: url("../fonts/MundialBold.otf");
}
@font-face {
  font-family: mundial-thin;
  src: url("../fonts/MundialThin.otf");
}

.ff_mundial_semibold {
  font-family: mundial-semibold;
}
.ff_mundial_bold {
  font-family: mundial-bold;
}
.ff_mundial_light {
  font-family: mundial-light;
}
.ff_mundial_thin {
  font-family: mundial-thin;
}

.ff_poppins {
  font-family: "Poppins", sans-serif;
}

.fw_thin {
  font-weight: 200;
}
@font-face {
  font-family: "Mundial";
  src: url("../fonts/MundialRegular.otf");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mundial";
  src: url("../fonts/MundialBold.otf");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Mundial";
  src: url("../fonts/MundialBlack.otf");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
html,
body {
  font-family: "Mundial", sans-serif;
  font-weight: 400;
  overflow-x: hidden;
  background-color: var(--color-white-off);
}
* {
  padding: 0;
  margin: 0;
  list-style-type: none;
  text-decoration: none !important;
}
.ff_mundial {
  font-family: "Mundial" !important;
}
.ff_montserrat {
  font-family: "Montserrat", sans-serif;
}
.ff_roboto {
  font-family: "Roboto", sans-serif;
}

:root {
  --font-xsm: 14px;
  --font-sm: 16px;
  --font-md: 18px;
  --font-lg: 20px;
  --font-xl: 22px;
  --font-2xl: 24px;
  --font-3xl: 25px;
  --font-4xl: 27px;
  --font-5xl: 30px;
  --font-6xl: 40px;
  --font-7xl: 48.18px;
  --font-8xl: 50px;
  --font-9xl: 128.4px;
  --color-white-off: #edfafb;
  --color-gray: #2d5363;
  --color-light-gray: rgba(83, 132, 153, 0.5);
  --color-light-green: #7ee49c;
  --color-light-green_2: #b5efcb;
  --color-dark-gray: #29424d;
}

@media (max-width: 1599.98px) {
  :root {
    --font-9xl: 80px;
  }
}
@media (max-width: 1499.98px) {
  :root {
    --font-xsm: 12px;
    --font-sm: 14px;
    --font-md: 15px;
    --font-lg: 17px;
    --font-xl: 22px;
    --font-2xl: 20px;
    --font-3xl: 22px;
    --font-4xl: 25px;
    --font-5xl: 26px;
    --font-6xl: 36px;
    --font-7xl: 38px;
    --font-8xl: 39px;
  }
}
@media (max-width: 1399.98px) {
  :root {
    --font-sm: 14px;
    --font-md: 15px;
    --font-lg: 17px;
    --font-xl: 22px;
    --font-2xl: 17px;
    --font-3xl: 22px;
    --font-4xl: 25px;
    --font-5xl: 23px;
    --font-6xl: 36px;
  }
}
@media (max-width: 1199.98px) {
  :root {
    --font-sm: 16px;
    --font-md: 14px;
    --font-lg: 15px;
    --font-xl: 18px;
    --font-2xl: 18px;
    --font-3xl: 22px;
    --font-4xl: 25px;
    --font-5xl: 24px;
    --font-6xl: 25px;
    --font-8xl: 32px;
    --font-9xl: 55px;
  }
}
@media (max-width: 991.98px) {
  :root {
    --font-sm: 12px;
    --font-md: 16px;
    --font-lg: 18px;
    --font-xl: 20px;
    --font-2xl: 20px;
    --font-3xl: 22px;
    --font-4xl: 22px;
    --font-5xl: 24px;
    --font-6xl: 32px;
    --font-7xl: 36px;
    --font-8xl: 40px;
    --font-9xl: 70px;
  }
}
@media (max-width: 767.98px) {
  :root {
    --font-sm: 16px;
    --font-md: 16px;
    --font-lg: 20px;
    --font-xl: 18px;
    --font-2xl: 18px;
    --font-3xl: 19px;
    --font-4xl: 20px;
    --font-5xl: 23px;
    --font-6xl: 28px;
    --font-7xl: 30px;
    --font-8xl: 35px;
  }
}
@media (max-width: 575.98px) {
  :root {
    --font-xsm: 12px;
    --font-sm: 12px;
    --font-md: 13px;
    --font-lg: 14px;
    --font-xl: 14px;
    --font-2xl: 22px;
    --font-2xl: 16px;
    --font-3xl: 17px;
    --font-4xl: 18px;
    --font-5xl: 18px;
    --font-6xl: 22px;
    --font-7xl: 26px;
    --font-8xl: 24px;
  }
}

.font_xsm {
  font-size: var(--font-xsm);
}
.font_sm {
  font-size: var(--font-sm);
}
.font_md {
  font-size: var(--font-md);
}
.font_lg {
  font-size: var(--font-lg);
}
.font_xl {
  font-size: var(--font-xl);
}
.font_2xl {
  font-size: var(--font-2xl);
}
.font_3xl {
  font-size: var(--font-3xl);
}
.font_4xl {
  font-size: var(--font-4xl);
}
.font_5xl {
  font-size: var(--font-5xl);
}
.font_6xl {
  font-size: var(--font-6xl);
}
.font_7xl {
  font-size: var(--font-7xl);
}
.font_8xl {
  font-size: var(--font-8xl);
}
.font_9xl {
  font-size: var(--font-9xl);
}
.bg_light_white {
  background-color: var(--color-white-off);
}
.bg_light_green {
  background-color: var(--color-light-green_2);
}
.bg_dark_gray {
  background-color: var(--color-dark-gray);
}
.color_white_off {
  color: var(--color-white-off);
}
.color_gray {
  color: var(--color-gray);
}
.color_dark_gray {
  color: var(--color-dark-gray);
}
.color_light_gray {
  color: var(--color-light-gray);
}
.color_light_green {
  color: var(--color-light-green);
}
.fw_light {
  font-weight: 300;
}
.fw_medium {
  font-weight: 500;
}
.ff_math {
  font-family: math !important;
}
.z_1 {
  z-index: 1;
}
.z_2 {
  z-index: 2 !important;
}
.z_5 {
  z-index: 5;
}
.z_negative {
  z-index: -1;
}
.z-10 {
  z-index: 10;
}
.opacity_0 {
  opacity: 0;
}
.opacity_05 {
  opacity: 0.5;
}
.opacity_09 {
  opacity: 0.9;
}
.pointer-events-none {
  pointer-events: none;
}
.origin-center {
  transform-origin: center;
}
.inset-0 {
  inset: 0;
}
.z-0 {
  z-index: 0;
}
.-z-10 {
  z-index: -10;
}
.letter_spacing {
  letter-spacing: 0.285714px;
}
.letter_spacing_01 {
  letter-spacing: -0.01em !important;
}
.custom_letter_spacing {
  letter-spacing: -0.01em !important;
}
.custom_tab_button_012 {
  transition: all 0.3s ease-in-out;
}
.custom_tab_button_012:hover {
  transform: rotate(-3deg) !important;
  background: rgba(83, 132, 153, 0.5) !important;
}
.cursor-pointer {
  cursor: pointer;
}
