.slider-partners {
  .slick-slide {
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#hero-sub-heading-one-tablet{
  display: none;
}
@media (min-width: 576px) {
  .custom_margin_footer {
    margin-top: 24px !important;
  }

  .custom_content_padding {
    padding-top: 80px !important;
  }

  .social_links_parent a img {
    width: 36px;
    height: 36px;
  }

  .contact_icon {
    width: 32px;
    height: 32px;
  }

  .w-sm-unset {
    width: unset !important;
  }

  .other-projects-list {
    a + a {
      margin-left: 50px;
    }
  }

}

@media (min-width: 768px) {
  .custom_content_padding {
    padding-top: 15px !important;
  }
}

.business_img_1 {
  border-radius: 26px;
}

@media (min-width: 992px) {
  .contact_icon {
    width: 39px;
    height: 39px;
  }

  .preloader_lottie {
    width: 50%;
  }

  .business_img_1 {
    transform: translate(70px, -40px);
    // width: 400px;
  }

  .business_img_2 {
    object-fit: contain;
    // min-width: 450px !important;
    transform: translate(-20px, -34px);
  }

  .business_img_3 {
    transform: translate(40px, -60px);
    max-width: 280px;
  }

  .business_img_4 {
    width: 300px !important;
    transform: translate(0px, -20px);
  }
}

@media screen and (max-width: 600px) and (min-height: 600px){
  #main-content{
    background-color: var(--color-dark-gray);
  }
}

@media screen and (min-width: 1000px) and (max-width: 1298px){
  .intro_tab_frame + div {
    margin-top: 665px;
  }
  .arrow-hero-two{
    margin-left: -400px !important;
  }
  .arrow-hero-three{
    display: none !important;
  }
  .for-tablets-too{
    display: block !important;
  }
  .for-desktop-only{
    display: none !important;
  }
  .small_icon_arrow{
    top: -25% !important;
    left: -2.5% !important;
  }
  .small_icon_arrow_2 {
    right: 15% !important;
  }
  .timeline_arrow_img {
    width: 450px;
    bottom: 34%;
    transform: translateY(74%);
  }
  .timeline_text1_max_w{
    margin: 0 auto !important;
    position: relative !important;
  }
  .text-slider-image {
    max-width: 50%;
  }
  .meeting_para{
    max-width: 6000px !important;
    font-size: 20px !important;
  }
  .manager_content{
    margin-top: 150px;
  }
  .footer_background_set{
    height: 610px !important;
  }
  .tablet-max-width{
    max-width: 660px !important;
  }
  .r-tablet-0,
  .l-tablet-0 {
    left: 0;
    right: 0;
  }
  .top-tablet {
    margin-top: 200px;
  }
  #CRM .slick-slider{
    margin-top: 130px;
    margin-bottom: 100px
  }

}

@media (min-width: 1200px) {
  .custom_tab_button_012 {
    font-size: 24px !important;
  }
  .personalizzate {
    font-size: 24px !important;
  }
  .partners_custom_height {
    min-height: 100vh;
  }
  .social_links_parent a img {
    width: 46px;
    height: 46px;
  }

  .navbar_links .font_sm {
    font-size: 16px !important;
  }

  .navbar_btn {
    font-size: 24px !important;
  }

  .business_img_1 {
    transform: translate(90px, -30px);
    width: 200px;
  }

  .business_img_2 {
    object-fit: contain;
    min-width: 450px !important;
    transform: translate(-20px, -34px);
  }

  .business_img_3 {
    max-width: 300px;
    transform: translate(60px, -50px);
  }

  .business_img_4 {
    width: 300px !important;
    transform: translate(60px, 10px);
  }
}

@media (min-width: 1300px) and (max-width: 1399.98px) {
  .common_container {
    max-width: 1280px !important;
  }
}

@media (min-width: 1400px) {
  #timeline-heading-3{
    right: 3rem !important;
  }
  #timeline-heading-4{
    left: -7rem !important;
  }
  .custom_margin_footer {
    margin-top: 30px !important;
  }

  .common_container {
    padding-left: 12px;
    padding-right: 12px;
  }

  // .business_img_2 {
  //   object-fit: contain;
  //   max-height: 500px;
  // }

  .business_img_1 {
    margin-left: 130px !important;
    transform: translate(70px, -40px);
    width: 250px;
  }

  .business_img_2 {
    object-fit: contain;
    min-width: 550px !important;
    transform: translate(70px, -54px);
  }

  .business_img_3 {
    max-width: 360px;
    transform: translate(120px, -50px);
  }

  .business_img_4 {
    width: 340px !important;
    transform: translate(170px, 10px);
  }
}

@media (min-width: 1600px) {
  .business_img_1 {
    margin-left: 170px !important;
    transform: translate(40px, -60px);
    width: 300px;
  }

  .business_img_2 {
    object-fit: contain;
    min-width: 670px !important;
    transform: translate(20px, -80px);
  }

  .business_img_3 {
    max-width: 420px;
    transform: translate(160px, -20px);
  }

  .business_img_4 {
    width: 440px !important;
    transform: translate(170px, 10px);
  }
  .hero_slide_1 {
    margin-top: 120px;
  }
  .hero_slide_2 {
    margin-top: 135px;
  }
  .hero_lottie_icon {
    top: 80%;
  }
  .e-img-01 {
    width: 23px;
    transform: translate(0px, -1px);
  }
  .custom_content_padding {
    padding-top: 120px !important;
  }

  .common_button {
    padding: 22px 24px 22px !important;
  }

  .footer_top_space {
    padding-top: 260px;
  }

  .about_img {
    height: 570px;
  }

  .timeline_icon_3 {
    max-width: 260px;
  }

  .tabs_lottie,
  .tabs_lottie_1 {
    top: 15% !important;
  }

  .tabs_lottie_width {
    width: 70%;
    margin-top: 10%;
  }

  .meeting_text_color {
    color: var(--color-white-off);
  }

  .divide_space_text {
    justify-content: space-between !important;
  }

  .custom_container {
    max-width: 1540px;
    margin: 0 auto;
    padding: 0 12px;
  }

  .hero_slide_para_2 {
    padding-top: 48px !important;
  }

  .number_heading {
    margin-top: 96px;
  }

  .common_container {
    max-width: 1547px !important;
  }

  .tabs_parent {
    min-height: 951px;
  }
}

@media screen and (min-width: 1700px){
  .tabs_lottie_width {
    width: 70%;
    margin-top: 10%;
  }
}

@media screen and (min-width: 1700px) and (max-height: 1000px){
  .tabs_lottie_width {
    margin-top: 0%;
  }
}

@media (min-width: 1400px) and (max-width: 1599.98px) {
  // .business_img_1 {
  //   margin-left: 72px !important;
  //   max-width: 360px !important;
  // }

  // .business_img_2 {
  //   max-height: 444px;
  //   max-width: 359px;
  //   margin-left: 190px;
  // }

  // .business_img_3 {
  //   max-width: 300px;
  //   margin-left: 150px;
  // }

  // .business_img_4 {
  //   width: 485px !important;
  //   margin-left: 20px !important;
  // }

  .business-content {
    transform: translateY(20px) !important;
    padding-top: 14px !important;
  }
}

@media (min-width: 1930px) {
  .tabs_content_parent {
    margin-top: 300px;
  }

  .tabs_lottie,
  .tabs_lottie_1 {
    top: 22% !important;
  }

  .tabs_lottie_width {
    width: 70%;
    margin-top: 10%;
  }
}

@media (max-width: 1799.98px) {
  .tab_right_icon {
    right: -21.5%;
  }
}

@media (max-width: 1599.98px) {
  .e-img-01 {
    width: 20px;
  }
  .meeting_text_color {
    color: white;
  }

  .custom_height_tab_img {
    max-height: 565px;
    width: 70% !important;
    left: 15% !important;
  }

  .tabs_parent {
    height: 144vh;
  }

  .tabs_lottie {
    top: 20%;
  }

  .tabs_lottie,
  .tabs_lottie_1 {
    top: 38% !important;
  }

  .i_pad_img_2 {
    transform: scale(0.76);
    margin-top: 64px;
    margin-left: -6px;
  }

  .footer_background_set {
    height: 740px;
  }

  .meeting_fissa_top_space {
    padding-top: 50px;
  }

  .custom_size_timeline_text {
    font-size: 16px;
  }

  .tab_text_position {
    transform: translateY(-120px);
  }

  .hero_slide_1 {
    margin-top: 53px;
  }

  .hero_slide_2 {
    margin-top: 56px;
  }

  .tab_right_icon {
    right: -15.5%;
  }
}

@media (max-width: 1499.98px) {
  .meeting_fissa_top_space {
    padding-top: 0px;
  }

  .footer_background_set {
    height: 680px;
  }

  .meeting_para {
    line-height: 260% !important;
    max-width: 1072px !important;
  }

  .meeting_para .common_button,
  .custome_size {
    font-size: 20px !important;
  }

  .small_icon {
    max-width: 39px !important;
    left: -60px !important;
  }

  .i_pad_img_2 {
    transform: scale(0.78);
    margin-top: 52px;
  }

  .tabs_lottie,
  .tabs_lottie_1 {
    top: 36.5% !important;
  }

  .hero_slide_3_btn {
    font-size: 28px !important;
  }

  .show_chat_icon {
    right: 95px;
  }

  .tab_right_icon {
    right: -11% !important;
  }
}

@media (max-width: 1399.98px) {
  .hero_lottie_icon {
    right: 32%;
  }
  .footer_top_space {
    padding-top: 190px;
  }

  .timeline_text1_max_w {
    max-width: 25rem;
  }

  .small_icon {
    left: 0%;
  }

  .tab_right_icon {
    right: -18% !important;
  }

  // .custom_business_img_height .business_img_1 {
  //   max-height: 300px;
  // }

  // .custom_business_img_height .business_img_2 {
  //   max-height: 330px;
  // }

  // .custom_business_img_height .business_img_3 {
  //   max-height: 200px;
  // }

  .custom_business_img_height {
    height: 350px !important;
    padding-left: 48px;
  }

  .custom_business_img_height img {
    object-fit: contain;
  }

  .small_icon {
    max-width: 30px !important;
    left: 15px !important;
  }

  .manager_content {
    transform: translateY(-160px);
  }

  .i_pad_img_2 {
    transform: scale(0.84);
    margin-top: 86px;
  }

  .tab_text_position {
    transform: translateY(-76px);
  }

  #main-assets {
    padding-bottom: 104px;
  }

  .tabs_lottie,
  .tabs_lottie_1 {
    top: 37% !important;
  }

  .tabs_lottie_width {
    width: 80%;
  }

  .crm_parent_img {
    flex-wrap: wrap;
  }

  .crm_parent_img .crm_img_size {
    transform: scale(0.9);
  }

  .crm_parent_img .crm_img_size img {
    max-width: 200px;
  }

  .hero_slide_1,
  .hero_slide_2 {
    margin-top: 50px;
  }
}

@media (max-width: 1299.98px) {
  .timeline_icon_3 {
    max-width: 150px;
  }

  .i_pad_img_2 {
    transform: scale(0.87);
  }

  .tab_right_icon {
    right: -14.2% !important;
  }
}

@media (max-width: 1199.98px) {
  .hero_lottie_icon {
    right: 28%;
    top: 55%;
  }
  .footer_top_space {
    padding-top: 0px;
  }

  .timeline_years h2 {
    letter-spacing: -1px;
  }

  .about_img {
    height: 400px;
  }

  .timeline_icon_3 {
    max-width: 110px;
  }

  .timeline_car_icon {
    max-width: 260px;
  }

  .text_parent_height {
    height: 75%;
  }

  .timeline_text1_max_w {
    top: 20%;
  }

  .tabs_lottie {
    top: 44% !important;
    width: 60% !important;
    left: 19% !important;
  }

  .tabs_lottie_1 {
    top: 44% !important;
    width: 60% !important;
    left: 19% !important;
  }

  .tab_text_position {
    transform: translateY(-106px) !important;
  }

  .small_icon_arrow {
    top: -5%;
    left: 0%;

    svg {
      max-width: 42px;
    }
  }

  .small_icon_arrow_2 {
    top: 70%;
    right: 30%;

    svg {
      max-width: 42px;
    }
  }

  .navbar_links.font_sm {
    font-size: 14px !important;
  }

  .navbar_btn {
    width: 87px;
    height: 42px;
  }

  .i_pad_img_2 {
    transform: scale(0.67);
    margin-left: -3px;
    margin-top: 82px;
  }

  .hero_slide_1 h3,
  .hero_slide_2 h3 {
    font-size: 48px !important;
  }

  .ipad_img {
    height: 65vh !important;
    padding-bottom: 30px;
  }

  .hero_slide_para_2 {
    font-size: 20px !important;
  }

  .meeting_para .common_button {
    font-size: 20px !important;
    padding: 14.4px 16px !important;
  }

  .footer_background_set {
    height: 510px;
  }

  .custom_size_timeline_text {
    font-size: 13px;
  }

  .timeline_btn {
    padding: 0px 15px 4px;
  }

  .parliamone {
    &::after {
      content: "";
      position: absolute;
      height: 60px;
      width: 1.69px;
      border: 1px solid var(--color-white-off);
      opacity: 0.5;
      background-color: var(--color-white-off);
      right: -25px;
      top: -16px;
    }
  }

  .btn_close_position {
    top: 35px;
    right: 25px;
    width: 3%;
  }

  .logo_width {
    max-width: 210px;
  }

  .navbar_links .font_sm {
    font-size: 14px !important;
  }

  .navbar_btn {
    width: 87px;
    height: 42px;
    padding: 7px 24px 10px;
  }

  .business-content h3 {
    font-size: 20px !important;
  }

  .business-content p {
    font-size: 16px !important;
    max-width: 360px !important;
  }

  .manager_content {
    transform: translateY(50px);
  }

  .arrow-hero-two {
    width: 750px;
    margin-left: -496px;
  }

  .tabs_parent {
    height: 164vh;
  }

  .crm_parent_img .crm_img_size img {
    max-width: 150px;
  }

  .hero_slide_3_btn {
    font-size: 24px !important;
    // width: 136px !important;
  }

  .intro_tab_frame {
    height: 740px;
  }

  .tab_right_icon {
    right: -13% !important;
  }
  .e-img-01 {
    height: 19px !important;
    transform: translate(0px, 1px);
  }

  .other-projects-list {
    margin-bottom: 0;
    img {
      display: inline-block;
      width: auto;
      height: 40px;
      margin-top: 1rem;
    }
  }
}




@media (max-width: 991.98px) {
  .hero_lottie_icon {
    right: 20%;
    top: 73%;
  }
  .e-img-01 {
    height: 25px !important;
    transform: translate(0px, 0px);
  }

  .location_icon {
    min-width: 32px;
  }

  .footer_background_set {
    height: 625px;
  }

  .meeting_para {
    max-width: 600px !important;
  }

  .footer_top_space {
    padding-top: 120px;
  }

  .navbar_btn {
    width: 135px;
    height: 55px;
  }

  .i_pad_img_2 {
    max-height: 750px;
    transform: unset;
    object-fit: contain;
    margin-top: 50px;
  }

  #main-assets {
    padding-bottom: 394px;
    transform: translateY(0px);
  }

  .tab_text_position {
    transform: translateY(-50px) !important;
  }

  .tabs_parent {
    height: 131vh;
  }

  .tabs_lottie,
  .tabs_lottie_1 {
    top: 38% !important;
  }

  .tabs_lottie_width {
    width: 100% !important;
  }

  .custom_content_size p,
  .custom_content_size button {
    font-size: 24px !important;
  }

  .timeline_icon_3 {
    max-width: 200px;
  }

  .timeline_text1_max_w {
    max-width: 45rem;
    top: 10%;
  }

  .timeline_content_4_parent {
    right: -70%;
  }

  .text_parent_height {
    top: 18%;
  }

  .timeline_btn {
    padding: 3px 25px 8px;
  }

  .small_icon_arrow_2 {
    top: 65%;
    right: 25%;
    top: unset;
    right: -20px;
    bottom: -40px;

    svg {
      max-width: 52px;
    }
  }

  .small_icon_arrow {
    left: 0%;

    svg {
      max-width: 52px;
    }
  }

  .about_parent {
    background-color: var(--color-light-green_2);
    height: 100% !important;
  }

  .roadmap_para {
    max-width: 621px;
    margin: auto;
    padding: 16px 19px 0px 52px;
  }

  .custom_top_spacing {
    margin-top: 8vh;
  }

  .custom_height_tab_img {
    left: 0% !important;
    width: 100% !important;
  }

  .i_pad_img {
    width: 150%;
  }

  .hero_slide_1 h3,
  .hero_slide_2 h3 {
    font-size: 80px !important;
  }

  .hero_slide_para_1 {
    font-size: 24px !important;
  }

  .hero_slide_para_2 {
    font-size: 32px !important;
  }

  .campagne_para {
    max-width: 520px !important;
    margin: auto !important;
  }

  .hero_slide_1 h2,
  .hero_slide_2 h2 {
    font-size: 32px !important;
  }

  .custom_size_timeline_text {
    font-size: 24px;
  }

  .timeline_car_icon {
    max-width: 340px;
  }

  .btn_close_position {
    top: 36px;
    right: 28px;
    width: 3%;
  }

  .btn_close_position svg {
    width: 40px;
    height: 40px;
  }

  .parliamone {
    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 100.69px;
      border: 1px solid var(--color-white-off);
      opacity: 0.5;
      background-color: var(--color-white-off);
      right: -5px;
      top: 35px;
    }
  }

  .parliamone {
    &::after {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      border: 1px solid transparent;
      opacity: 0.5;
      background-color: transparent;
      right: -30px;
      top: -20px;
    }
  }

  .ipad_img {
    max-width: 665px;
    height: 80vh !important;
  }

  .manager_content {
    transform: translateY(-50px);
  }

  .hero-outline-arrow svg {
    width: 100vw;
  }

  .hero_slide_3_btn {
    font-size: 28px !important;
  }

  .crm_parent_img .crm_img_size {
    transform: scale(1);
  }

  .crm_parent_img .crm_img_size img {
    max-width: 210px;
    min-width: 210px;
    padding: 0 16px;
    height: 146px;
  }

  .show_chat_icon {
    right: 10px;
  }

  .header_section {
    left: 0 !important;
  }

  .intro_tab_frame {
    height: 1170px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1298px){
  .hero_lottie_icon{
    right: 34% !important;
    top: 70% !important;
  }
}

@media (max-width: 799.98px) {
  .hero_slide_1,
  .hero_slide_2 {
    margin-top: 25px;
  }
  .hero_slide_para_2 {
    max-width: 526px;
    margin: auto;
  }

  .i_pad_img_2 {
    max-height: 670px;
  }

  #main-assets {
    transform: translateY(70px);
  }

  .tabs_lottie,
  .tabs_lottie_1 {
    top: 36% !important;
  }

  .intro_tab_frame {
    height: 950px;
  }

  .ipad_img {
    max-width: 645px;
  }

  .other-projects-list {
    margin-bottom: -2rem;
    img {
      display: inline-block;
      width: auto;
      height: 40px;
      margin-top: 1rem;
    }

    a + a {
      margin-left: 20px;
    }
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .timeline_btn {
    font-size: 21px !important;
    padding: 0px 30px;
    display: inline-block;
    height: 39px;
  }

  .timeline_car_icon {
    max-width: 473px;
  }

  .timeline_tiguido_icon {
    max-width: 259px;
  }

  .timeline_icon_3 {
    width: 328px;
    max-width: 328px;
  }

  #timeline-heading-1 p,
  #timeline-heading-2 p,
  #timeline-heading-3 p,
  #timeline-heading-4 p {
    font-size: 20px !important;
    line-height: 147%;
  }

  #timeline-heading-4 .timeline_btn {
    height: 30px !important;
    font-size: var(--font-md) !important;
    padding: 0px 41px !important;
  }

  .timeline_content_4_parent {
    right: -55%;
  }
}

@media (max-width: 767.98px) {
  .small_tab_arrow_img {
    height: 310vh;
  }

  .roadmap_para {
    max-width: 100%;
  }

  .custom_size_timeline_text {
    font-size: 16px;
  }

  .timeline_car_icon {
    max-width: 150px;
  }

  .timeline_btn {
    padding: 4px 20px 4px;
    vertical-align: middle !important;
  }

  .timeline_text_heading {
    white-space: inherit;
    width: 100%;
  }

  .parliamone {
    &::after {
      content: "";
      position: absolute;
      height: 2px;
      width: 100.69px;
      border: 1px solid var(--color-white-off);
      opacity: 0.5;
      background-color: var(--color-white-off);
      right: -15px;
      top: 30px;
    }
  }

  .crm_parent_img .crm_img_size img {
    max-width: 140px;
    min-width: 140px;
    height: 86px;
    padding: 0 12px;
  }

  .logo_width {
    max-width: 175px;
  }

  .toggle_icon svg {
    max-width: 26px !important;
  }
}

@media (max-width: 575.98px) {
  .arrow-hero-two {
    margin-left: -96px;
  }
  .timeline_arrow_img {
    bottom: 32% !important;
    width: 100%;
  }
  .timeline_text_size {
    font-size: 24px;
  }
  .custom_tab_button_012 {
    font-size: 20px !important;
  }
  .acquired_in_time{
    display: block !important;
  }
  .hero_slide_1,
  .hero_slide_2 {
    margin-top: 0px !important;
    transform: scale(0.9);
  }
  .hero_slide_1{
    margin-top: -150px !important;
  }
  .e-img-01 {
    height: 24px !important;
    transform: translate(2px, -4px);
  }
  .hero_lottie_icon {
    right: 0%;
    top: 68%;
  }
  .partner_para {
    font-size: 28px !important;
    max-width: 289px;
  }

  .partner_para button {
    font-size: 24px !important;
  }

  .timeline_btn {
    border-radius: 7px !important;
  }

  .footer_top_space {
    padding-top: 95px;
  }

  .timeline_content_4_parent {
    right: 0;
  }

  .timeline_tiguido_icon {
    max-width: 145px;
  }

  .footer_social_icon svg {
    width: 40px;
    height: 40px;
  }

  #footer .list-unstyled {
    display: inline-block !important;
  }

  .custom_content_size p,
  .custom_content_size button {
    font-size: 16px !important;
  }

  .i_pad_img_2 {
    max-height: 400px;
    margin-top: -40px !important;
  }

  .tab_text_position {
    transform: translateY(0px) !important;
    margin-bottom: 4px !important;
  }

  .tabs_lottie {
    top: 4% !important;
  }

  .timeline_icon_3 {
    max-width: 150px;
  }

  .timeline_line_img {
    left: 6px;
  }

  .timeline_arrow_icon {
    top: 71.5%;
  }

  .timeline_dots {
    height: 20px;
    width: 20px;
  }

  .text_parent_height {
    top: 15%;
    height: 80%;
  }

  .i_pad_img_2 {
    margin-left: unset;
  }

  #main-assets {
    transform: translateY(48px);
  }

  .tabs_parent {
    height: 138vh;
  }

  .tabs_lottie_width {
    width: 100%;
  }

  .tabs_lottie_1 {
    top: 39% !important;
  }

  .tabs_lottie {
    top: 38% !important;
  }

  .tabs_btn {
    padding: 8px;
  }

  .small_icon_arrow {
    top: 0%;
    left: -2%;
    width: 70%;

    svg {
      max-width: 24px;
    }
  }

  .roadmap_para {
    padding: 16px 14px 0px 16px;

    h4 {
      line-height: 142%;
    }
  }

  .small_icon_arrow_2 {
    svg {
      max-width: 24px;
    }
  }

  .custom_top_spacing {
    margin-top: unset;
  }

  .common_button {
    padding: 10px 24px 13px !important;
  }

  .meeting_man_img {
    width: 35px;
    height: 35px;
  }

  .custom_content_padding {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }

  .meeting_custom_top_space {
    margin-top: 10px;
  }

  .contact_icon {
    width: 18px;
    height: 18px;
  }

  .footer_logo {
    width: 114px;
    height: 46px;
    margin-top: 90px;
    margin-bottom: 38px;
  }

  .meeting_btn_parent a {
    font-size: 20px;
  }

  .custom_margin_footer {
    margin-top: 16px;
  }

  .business_parent {
    background: var(--color-light-green);
  }

  .hero_slide_para_2 {
    font-size: 20px !important;
  }

  .hero_slide_1 h2,
  .hero_slide_2 h2 {
    max-width: 285px !important;
    margin: auto !important;
  }

  .custom_business_heading {
    font-size: 25px !important;
    line-height: 90% !important;
  }

  .meeting_para {
    min-width: 355px !important;
    max-width: 370px !important;
    font-size: 24px !important;
    line-height: 208% !important;
  }

  .meeting_para .common_button {
    font-size: 24px !important;
    height: 37px !important;
    padding: 6.4px 16px !important;
  }

  .timeline_btn {
    padding: 2px 10px 4px;
  }

  .meeting_btn_parent button {
    font-size: 20px !important;
    padding: 8px 38px !important;
  }

  .fissa-meeting-content-parent p {
    font-size: 16px !important;
    line-height: 112% !important;
    color: var(--color-white-off) !important;
  }

  .social_links_parent a img {
    max-width: 24px !important;
  }

  .list_item_text {
    font-size: 14px !important;
  }

  .footer_background_set {
    height: 685px;
  }

  .footer_social_icons1 {
    width: 23px;
    height: 23px;
  }

  .about_text_size p,
  .about_text_size_two p,
  .hero_slide_1 h2,
  .hero_slide_2 h2,
  .custom_business_heading{
    font-size: 28px !important;
  }

  .custom_size_timeline_text {
    font-size: 14px;
  }

  .logo_width {
    max-width: 175px;
    min-height: 30.93px;
  }

  .btn_close_position {
    top: 26px;
    right: 21px;
    width: 5%;
  }

  .btn_close_position svg {
    width: 20px;
    height: 20px;
  }

  .navbar_btn {
    padding: 0px 24px 2px;
    max-width: 90px;
    height: 35px;
  }

  .navbar_overlay_links ul li a,
  .sentia_link {
    font-size: 4vw !important;
  }

  .navbar_btn {
    transform: scale(1.1);
  }

  .hero_heading_btn {
    font-size: 24px !important;
    display: inline-block !important;
    vertical-align: middle !important;
    padding: 10px 24px 13px !important;
  }

  .topscroll {
    bottom: 12px;
    left: 12px;
  }

  .show_chat_icon {
    right: 3px;
  }

  .chat_icon svg {
    width: 40px;
  }

  .timeline_btn_padding {
    padding: 10px 24px 13px !important;
  }

  .hero_slide_3_btn {
    width: auto !important;
  }

  #hero-main-heading {
    max-width: 254px !important;
    margin: 0 auto !important;
    font-size: 32px !important;
  }

  .hero_slide_1 h3,
  .hero_slide_2 h3 {
    font-size: 60px !important;
  }

  .manager_content {
    transform: translateY(-10px);
  }

  .ipad_img {
    height: 72vh !important;
    max-width: 96%;
  }

  .tabs_lottie_width {
    min-width: 100vw;
  }

  .crm_image_size {
    min-height: 126px;
  }

  .tabs_btn {
    height: 42px !important;
    margin-bottom: 4px !important;
  }

  .intro_tab_frame {
    height: 670px;
  }

  #partners .container,
  #CRM .container {
    padding: 0px !important;
  }
}

@media (max-width: 364px) {
  .custom_content_size p,
  .custom_content_size button {
    font-size: 13px !important;
  }
}

@media (min-width: 380px) and (max-width: 450px) {
  .timeline_arrow_icon {
    top: 72.5%;
  }
  .timeline_arrow_img {
    bottom: 34% !important;
    width: 100%;
  }
  #CRM .slick-slider{
    margin-top: 130px;
    margin-bottom: 100px
  }
  #CRM .crm_image{
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 374.98px) {
  .meeting_para {
    min-width: 100% !important;
    font-size: 18px !important;
  }

  .meeting_para .common_button {
    font-size: 18px !important;
  }

  .timeline_arrow_icon {
    top: 72.5%;
  }
}

@media (max-width: 1199.98px) and (min-height: 700px) {
  .tabs_lottie,
  .tabs_lottie_1 {
    top: 39% !important;
  }

  .tabs_lottie_width {
    width: 100% !important;
  }

  .tabs_parent {
    // height: 140vh !important;
    min-height: 100% !important;
  }
}

@media (max-width: 799.98px) and (max-height: 1199.98px) {
  .tabs_lottie,
  .tabs_lottie_1 {
    top: 33% !important;
  }
}

@media (max-width: 575.98px) and (min-height: 700px) {
  // .tabs_parent {
  //   height: 841px !important;
  // }

  // .tabs_lottie {
  //   top: 28% !important;
  // }

  // .tabs_lottie_1 {
  //   top: 31% !important;
  // }

  .tabs_lottie_width {
    width: 100% !important;
  }

  .tabs_lottie,
  .tabs_lottie_1 {
    left: calc((var(--bs-gutter-x) * -3)) !important;
    top: 47.5% !important;
    min-width: 490px;
  }

  .ipad_img {
    height: 76vh !important;
    max-width: 116% !important;
  }
}

@media (max-width: 1399.998px) and (max-height: 599.98px) {
  .intro_tab_frame {
    height: 670px;
  }
}

@media (max-width: 1399.98px) and (max-height: 699.98px) {
  .ipad_img {
    height: 90vh;
  }

  #hero-ipad-white {
    transform: translate(-50%, -50%) scale(0.8, 0.8) !important;
  }

  .intro_tab_frame {
    height: 0vh;
  }

  .arrow-hero-one {
    transform: scale(0.7, 0.7) !important;
    margin-top: 50px !important;
  }

  .tab_text_position {
    transform: translateY(-50px) !important;
  }

  .tab_text_position p,
  .tab_text_position button {
    font-size: 15px !important;
  }

  .tab_text_position button {
    height: 38px !important;
  }
}

@media (max-width: 1199.98px) and (max-height: 991.98px) {
  .arrow-hero-one {
    transform: scale(0.6, 0.6) !important;
  }
  .arrow-hero-two {
    margin-left: -396px;
  }
  .intro_tab_frame {
    height: 740px;
  }
}

@media (max-width: 360px) and (max-height: 991.98px) {
  .intro_tab_frame {
    height: 0vh;
  }
}

@media (min-width: 361px) and (max-width: 440px) and (max-height: 991.98px) {
  .intro_tab_frame {
    height: 34vh;
  }
}

@media (max-width: 1199.98px) and (max-height: 899.98px) {
  .arrow-hero-two {
    margin-left: -96px;
  }
}

@media (max-width: 1199.98px) and (max-height: 699.98px) {
  // .intro_tab_frame {
  //   height: 624px;
  // }
  .arrow-hero-one {
    transform: scale(0.8, 0.8) !important;
    margin-top: 8px !important;
  }
}

@media (max-width: 1199.98px) and (max-height: 799.98px) {
  .arrow-hero-one {
    transform: scale(0.55, 0.55) !important;
    margin-top: 40px !important;
  }

  .ipad_img {
    height: 75vh !important;
  }
}

@media (max-width: 575.98px) and (min-height: 800px) {
  // .tabs_parent {
  //   height: 1021px !important;
  // }

  .tabs_lottie,
  .tabs_lottie_1 {
    left: calc((var(--bs-gutter-x) * -0.5)) !important;
    top: 47% !important;
    min-width: 430px;
  }

  .ipad_img {
    height: 72vh !important;
    max-width: 95% !important;
  }

  // .tabs_lottie_width {
  //   width: 120% !important;
  // }

  // #hero-ipad,
  #hero-ipad-white {
    transform: translate(-50%, -44%) scale(1, 1) !important;
    // transform: translate(-50%, -40%) scale(1.321, 1.321) !important;
  }
}

@media (max-width: 991.98px) and (max-height: 399.98px) {
  #main-content {
    transform: translate(0px, -50px) !important;
  }
  .arrow-hero-one {
    transform: scale(0.4) !important;
  }
  .arrow-hero-two {
    max-width: 390px !important;
    margin-left: 150px !important;
    margin-top: 30px !important;
  }
  .hero_slide_1 h3,
  .hero_slide_2 h3 {
    font-size: 32px !important;
  }
  .hero_slide_1 h2,
  .hero_slide_2 h2 {
    font-size: 16px !important;
  }
  .hero_slide_para_1,
  .hero_slide_para_2 {
    font-size: 18px !important;
    max-width: 450px !important;
    margin-bottom: 0px !important;
  }
  .campagne_para {
    max-width: 440px !important;
    padding-top: 24px !important;
  }
  .hero_slide_3_btn {
    font-size: 16px !important;
    // width: 96px !important;
  }
  .tabs_parent {
    height: 1500px !important;
  }
  .tabs_lottie_width {
    width: 50% !important;
  }
  .tabs_lottie,
  .tabs_lottie_1 {
    top: 52.6% !important;
    left: 16% !important;
    width: 70% !important;
  }
  .ipad_img {
    height: 90vh !important;
    margin-top: 40px;
  }
  .custom_content_size p,
  .custom_content_size button {
    font-size: 16px !important;
  }
  #hero-main-heading {
    margin-bottom: 40px !important;
  }
  .logo_width {
    max-width: 190px;
  }
  .toggle_icon svg {
    max-width: 30px !important;
  }
  #hero-ipad-white {
    transform: translate(-50%, -64%) scale(0.6, 0.6) !important;
  }
  .intro_tab_frame {
    height: 890px !important;
  }
  .hero_slide_1 {
    margin-top: 80px;
  }
  .hero_slide_2 {
    margin-top: 88px;
  }
  .hero_slide_para_2 {
    padding-top: 28px !important;
  }
  .tab_text_position {
    transform: translateY(-10px) !important;
    padding-top: 0px !important;
  }
}

@media (max-width: 767.98px) and (max-height: 399.98px) {
  #main-content {
    transform: translate(0px, -30px) !important;
  }
  .tabs_lottie_width {
    width: 60% !important;
  }
  .tabs_lottie,
  .tabs_lottie_1 {
    top: 50% !important;
  }
  .custom_content_size p,
  .custom_content_size button {
    font-size: 20px !important;
  }
  .tabs_parent {
    height: auto !important;
  }
  .hero_slide_1 h2,
  .hero_slide_2 h2 {
    font-size: 15px !important;
    margin-top: 24px;
  }
  .hero_slide_1 h3,
  .hero_slide_2 h3 {
    font-size: 26px !important;
    padding: 8px 0px !important;
  }
  .hero_slide_para_1,
  .hero_slide_para_2 {
    font-size: 16px !important;
    max-width: 450px !important;
    margin-bottom: 0px !important;
  }
  .campagne_para {
    max-width: 340px !important;
    padding-top: 16px !important;
    font-size: 16px !important;
  }
  .intro_tab_frame {
    height: 830px !important;
  }
  #hero-ipad-white {
    transform: translate(-50%, -72%) scale(0.7, 0.7) !important;
  }
  .tabs_lottie,
  .tabs_lottie_1 {
    top: 47% !important;
  }
  .tab_text_position {
    padding-top: 0px !important;
  }
  .tab_text_position p,
  .tab_text_position button {
    font-size: 13px !important;
  }
  .tab_text_position button {
    height: 32px !important;
    padding: 9px 24px 10px;
  }
}

@media (max-width: 575.98px) and (max-height: 699.98px) {
  .tabs_parent {
    height: 155vh !important;
  }

  .tabs_lottie,
  .tabs_lottie_1 {
    // left: -180px !important;
    // top: 46.5% !important;
    // min-width: 430px;

    left: -200px !important;
    top: 43.5% !important;
    min-width: 480px;
  }

  #main-assets {
    transform: translateY(78px);
  }

  #hero-ipad-white {
    transform: translate(-50%, -44%) scale(1.011, 1.011) !important;
  }
}

@media (min-width: 1600px) and (max-height: 799.98px) {
  .intro_tab_frame {
    height: 18vh;
  }
}

@media (min-width: 1600px) and (min-height: 800px) {
  .tabs_parent {
    min-height: 100%;
    height: 100%;
  }

  .intro_tab_frame {
    height: 0;
  }
}

@media (min-width: 1930px) and (min-height: 800px) {
  .intro_tab_frame {
    height: 0;
  }

  .custom_content_size p,
  .custom_content_size button {
    font-size: 27px !important;
  }

  .max_width_tabs {
    max-width: 1320px;
    width: 1320px;
  }
}

@media (min-width: 1600px) and (max-height: 959.98px) {
  #main-assets {
    padding-bottom: 174px;
    transform: translateY(0px);
  }

  .tabs_lottie,
  .tabs_lottie_1 {
    top: 33% !important;
  }

  .tabs_lottie_width {
    width: 67%;
  }

  .hero_custom_container {
    margin-top: -40px;
  }

  .ipad_img {
    height: 70vh;
  }

  .hero_slide_1 h3,
  .hero_slide_2 h3 {
    margin-top: 40px !important;
  }

  .hero_slide_para_2 {
    padding-top: 24px !important;
  }
}

@media (min-height: 1400px) {
  .timeline_arrow_img {
    bottom: -31%;
  }
}

@media (max-height: 1100px) {
  .timeline_arrow_img {
    bottom: 80.5%;
    transform: translateY(81.5%);
  }
}

@media (max-height: 1079px) {
  .timeline_arrow_img {
    bottom: 74%;
    transform: translateY(74%);
  }
}

@media (max-height: 1024px) {
  .timeline_arrow_img {
    bottom: 74%;
    transform: translateY(74%);
  }
}

@media (max-height: 1024px) and (max-width: 768px) {
  .timeline_arrow_img {
    bottom: 66%;
    transform: translateY(74%);
  }
}

@media (max-height: 1024px) and (max-width: 1400px) {
  .other-projects-list {
    margin-bottom: -6.5rem;
    margin-top: 20px;
  }
}

@media (max-height: 800px) {
  .timeline_arrow_img {
    bottom: 66%;
    transform: translateY(66%);
  }
}

@media (max-height: 700px) {
  // .timeline_arrow_img {
  //   bottom: 64%;
  //   transform: translateY(64%);
  // }
  .timeline_arrow_img {
    bottom: 32%;
    transform: translateY(64%);
    width: 100%;
  }
}

@media (max-height: 600px) {
  .timeline_arrow_img {
    bottom: 44%;
    transform: translateY(60%);
  }
}

@media (max-width: 668px) and (max-height: 376px) {
  .text_parent_height {
    height: 106% !important;
  }

  .timeline_dots {
    height: 20px;
    width: 20px;
  }

  .timeline_line_img {
    left: 18px;
  }

  .timeline_car_icon {
    max-width: 185px;
  }

  .timeline_arrow_img {
    bottom: 51%;
  }

  .timeline_tiguido_icon {
    max-width: 140px;
  }
}

@media screen and (max-height: 1004px) and (min-width: 1600px){
  .hero_lottie_icon {
    top: 79%;
  }
}

@media screen and (max-height: 800px) and (min-width: 1600px){
  .hero_lottie_icon {
    top: 77%;
  }
}

@media screen and (max-height: 720px) and (min-width: 1200px){
  .hero_lottie_icon {
    top: 75%;
  }
}

@media screen and (max-height: 650px) and (min-width: 1200px){
  .hero_lottie_icon {
    top: 73%;
  }
}

@media screen and (max-height: 600px) and (min-width: 1200px){
  .hero_lottie_icon {
    top: 72%;
  }
}

@media screen and (max-height: 550px) and (min-width: 1200px){
  .hero_lottie_icon {
    top: 68%;
  }
}

@media screen and (max-height: 500px) and (min-width: 1200px){
  .hero_lottie_icon {
    top: 66%;
  }
}

@media screen and (max-height: 470px) and (min-width: 1200px){
  .hero_lottie_icon {
    top: 64%;
  }
}

@media (min-width: 834px) and (max-width: 999px) {
  .tabs_content_parent {
    margin-top: 1350px !important;
  }
}

.card_img_0 {
  margin-bottom: 0;
}

@media (min-width: 1024px) and (min-height: 1024px) {
  .card_img_0 {
    margin-bottom: 13vh;
  }
}

@media (min-width: 1024px) and (max-height: 1023px) {
  .card_img_0 {
    margin-bottom: 2vh;
  }
}

@media (min-width: 1400px) and (max-width: 1480px) {
  .card_img_0 {
    margin-bottom: 9vh;
  }
}

/* video responsive */
.video-responsive-frame {
  display: inline-block;
  border-radius: 36px;
  border: 0px;
  overflow: hidden;
  height: 74.5vh;
  width: 29.7vh;
}

@media (max-width: 360px) {
  .video-responsive-container {
    margin-top: 35vh !important;

    .video-responsive-frame {
      height: 57.7vh;
      width: 27.9vh;
      border-radius: 4.5vh;

      video {
        height: 59vh;
        margin-top: -0.6vh;
        margin-left: -2.7vh;
      }
    }
  }
}

@media (min-width: 361px) and (max-width: 420px) {
  .video-responsive-container {
    margin-top: 1vh !important;

    .video-responsive-frame {
      height: 53vh;
      width: 26.4vh;
      border-radius: 28px;

      video {
        height: 55vh;
        margin-top: -0.8vh;
        margin-left: -2.2vh;
      }
    }
  }
}

@media (min-width: 421px) and (max-width: 768px) {
  .video-responsive-container {
    margin-top: -9vh !important;

    .video-responsive-frame {
      height: 64.3vh;
      width: 31.8vh;
      border-radius: 45px;

      video {
        height: 66vh;
        margin-top: -0.8vh;
        margin-left: -2.7vh;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .video-responsive-container {
    margin-top: 40vh !important;

    video {
      height: 62vh;
      margin-top: -0.8vh;
      margin-left: -2.6vh;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1200px) {
  .video-responsive-container {
    margin-top: 17vh !important;

    .video-responsive-frame {
      height: 80.5vh;
      width: 39.7vh;
      border-radius: 75px;

      video {
        height: 82.2vh;
        margin-top: -0.8vh;
        margin-left: -3.2vh;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1400px) {
  .video-responsive-container {
    margin-top: 15vh !important;

    .video-responsive-frame {
      border-radius: 5vh;
      height: 60.3vh;

      video {
        height: 62vh;
        margin-top: -0.8vh;
        margin-left: -2.6vh;
      }
    }
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .video-responsive-container {
    margin-top: 11vh !important;

    .video-responsive-frame {
      height: 60.5vh;
      width: 29.7vh;
      border-radius: 5vh;
    }

    video {
      height: 62vh;
      margin-top: -0.8vh;
      margin-left: -2.6vh;
    }
  }
}

@media (min-width: 1600px) and (max-width: 1900px) {
  .video-responsive-container {
    margin-top: 20vh !important;

    .video-responsive-frame {
      height: 60.5vh;
      width: 29.7vh;
      border-radius: 5vh;
    }

    video {
      height: 62vh;
      margin-top: -0.8vh;
      margin-left: -2.6vh;
    }
  }
}

@media (min-width: 1900px) {
  .video-responsive-container {
    margin-top: 21vh !important;

    .video-responsive-frame {
      height: 60vh;
      width: 29.7vh;
      border-radius: 33px;
    }

    video {
      height: 62vh;
      margin-top: -0.8vh;
      margin-left: -2.6vh;
    }
  }
}
